import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

class Header extends React.Component {
  appendScript (filePath) {
    const script = document.createElement('script')
    script.src = filePath
    document.body.appendChild(script)
  }

  componentDidMount () {
    let scriptPaths = [
      'https://js.gleam.io/e.js',
      '/js/magnific-popup.js',
      '/js/jquery.min.js',
      '/js/default-assets/active.js',
      '/js/owl.carousel.min.js',
      '/js/bootstrap.min.js',
      '/js/confer.bundle.js'
    ]
    scriptPaths.forEach(filePath => {
      if (document.body.innerHTML.indexOf(filePath) === -1) {
        this.appendScript(filePath)
      }
    })
  }

  

  render () {
    return (
      <StaticQuery
        query={ query }
    render={
      data => {
        let coursesJson = data.allCoursesJson.edges
        let courses = {}
        coursesJson.forEach(courseJson => {
          let course = courseJson.node
          if (!(course.pyramid in courses)) {
            courses[course.pyramid] = []
          }
          courses[course.pyramid].push({
            slug: course.slug,
            title: course.title
          })
        })

        let pyramidListHtml = []
        Object.keys(courses).forEach(function (pyramid) {
          let coursesListHtml = []
          courses[pyramid].forEach(course => {
            coursesListHtml.push(
              <li key={course.slug}><a href={'/training-courses/' + course.slug}>{course.title}</a></li >
            )
          })
          pyramidListHtml.push(
            // eslint-disable-next-line
            <li key={pyramid}><a href='#'>{pyramid}</a>
              <ul className='dropdown'>
                {coursesListHtml}
              </ul>
            </li>
          )
        })
    return (
      <header className='header-area'>
        <div className='classy-nav-container breakpoint-off'>
          <div className='container'>
            {/* <!-- Classy Menu --> */}
            <nav className='classy-navbar justify-content-between' id='conferNav'>

              {/* <!-- Logo --> */}
              <a className='nav-brand' href='/'><img src='/img/core-img/vertical-logo-new.png' alt='' /></a>

              {/* <!-- Navbar Toggler --> */}
              <div className='classy-navbar-toggler'>
                <span className='navbarToggler'><span /><span /><span /></span>
              </div>

              {/* <!-- Menu --> */}
              <div className='classy-menu'>
                {/* <!-- Menu Close Button --> */}
                <div className='classycloseIcon'>
                  <div className='cross-wrap'><span className='top' /><span className='bottom' /></div>
                </div>
                {/* <!-- Nav Start --> */}
                <div className='classynav'>
                  <ul id='nav'>
                    <li><a href='/'>Home</a></li>
                    <li><a href='#about'>About Us</a></li>
                    {/* <li><a href='#'>Courses</a> */}
                      {/* <ul className='dropdown'>
                        <li><a href='#welcome-area'>- Home</a></li>
                        
                        <li><a href='/speakers.html'>- Speakears</a></li>
                        <li><a href='/schedule.html'>- Schedule</a></li>
                        <li><a href='/tickets.html'>- Tickets</a></li>
                        <li><a href='/blog.html'>- Blog</a></li>
                        <li><a href='/single-blog.html'>- Single Blog</a></li>
                        <li><a href='/contact.html'>- Contact</a></li>
                        <li><a href='#'>- Dropdown</a>
                          <ul className='dropdown'>
                            <li><a href='#'>- Dropdown Item</a></li>
                            <li><a href='#'>- Dropdown Item</a></li>
                            <li><a href='#'>- Dropdown Item</a></li>
                            <li><a href='#'>- Dropdown Item</a></li>
                          </ul>
                        </li>
                      </ul> */}
                      {/* <ul className='dropdown'>
                        {pyramidListHtml}
                      </ul>
                    </li> */}
                    {/* <li><a href='/speakers.html'>Speakears</a></li> */}
                    <li><a href='#products' id='scroll-products'>Products</a></li>
                    <li><a target="_blank" href='http://robotickitchen.in/blog/'>Blog</a></li>
                    <li><a href='#contact' id='scroll-contact'>Contact</a></li>
                  </ul>

                  {/* <!-- Get Tickets Button --> */}
                  <a href='#apps'  id='scroll-apps' className='btn confer-btn mt-3 mt-lg-0 ml-3 ml-lg-5'>Download App Now! <i className='zmdi zmdi-long-arrow-right' /></a>
                </div>
                {/* <!-- Nav End --> */}
              </div>
            </nav>
          </div>
        </div>
      </header>
    )
  }
}
  />
  )
}
}

export default Header
export const query = graphql`
query AllCoursesQuery {
  allCoursesJson {
    edges {
      node {
        id
        slug
        title
        pyramid
      }
    }
  }
}
`