import React from 'react'

export default props => (
  <footer className='footer-area bg-img bg-overlay-2 section-padding-100-0'>
    {/* <!-- Main Footer Area --> */}
    <div className='main-footer-area'>
      <div className='container'>
        <div className='row'>
          {/* <!-- Single Footer Widget Area --> */}
          <div className='col-12 col-sm-6 col-lg-3'>
            <div className='single-footer-widget mb-60 wow fadeInUp' data-wow-delay='300ms'>
              {/* <!-- Footer Logo --> */}
              <a href='https://www.robochef.co' className='footer-logo'><img src='/img/core-img/vertical-log.png' loading="lazy" alt='RoboChef - Robotic Kitchen India' /></a>
              <p>India's First Robotic Restaurant.</p>

              {/* <!-- Social Info --> */}
              <div className='social-info'>
                <a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/robotickitchen/'><i className='zmdi zmdi-instagram' /></a>
                <a target="_blank" rel="noopener noreferrer" href='https://twitter.com/RoboticKitchen'><i className='zmdi zmdi-twitter' /></a>
                <a target="_blank" rel="noopener noreferrer" href='https://www.youtube.com/channel/UCjuIHhvnVUxa97B7gLFIHTQ'><i className='zmdi zmdi-youtube' /></a>
              </div>
            </div>
          </div>

          {/* <!-- Single Footer Widget Area --> */}
          <div className='col-12 col-sm-6 col-lg-3'>
            <div className='single-footer-widget mb-60 wow fadeInUp' data-wow-delay='300ms'>
              {/* <!-- Widget Title --> */}
              <h5 className='widget-title'>Contact</h5>

              {/* <!-- Contact Area --> */}
              <div className='footer-contact-info'>
                <p><i className='zmdi zmdi-map' /> 
                    Kanniyappan Street, <br />
                    Ramapuram<br />
                    Chennai - 600089 <br /></p>
                    <a href="tel:+919789968765"><p><i className='zmdi zmdi-phone' /> +91 97899 68765</p></a>
                    <a href="mailto:robotickitchenindia@gmail.com"><p><i className='zmdi zmdi-email' /> robotickitchenindia@gmail.com</p></a>
                <p><i className='zmdi zmdi-globe' /> robochef.co</p>
              </div>
            </div>
          </div>

          {/* <!-- Single Footer Widget Area --> */}
          {/* <div className='col-12 col-sm-6 col-lg-3'>
            <div className='single-footer-widget mb-60 wow fadeInUp' data-wow-delay='300ms'>
              <h5 className='widget-title'>Useful Links</h5>

              <ul className='footer-nav'>
                <li><a href='/'>Home</a></li>
                <li><a href='#about' id='scroll-about'>About us</a></li>
                <li><a href='#services' id='scroll-services'>Dishes</a></li>
                <li><a href='#contact' id='scroll-contact'>Contact</a></li>
              </ul>
            </div>
          </div> */}

          {/* <!-- Single Footer Widget Area --> */}
          <div className='col-12 col-sm-6 col-lg-3'>
            <div className='single-footer-widget mb-60 wow fadeInUp' data-wow-delay='300ms'>
              {/* <!-- Widget Title --> */}
              <h5 className='widget-title'>Gallery</h5>

              {/* <!-- Footer Gallery --> */}
              <div className='footer-gallery'>
                <div className='row'>
                  <div className='col-4'>
                    <a href='/img/recipes/gallery/1.jpg' data-group="1" className="galleryItem"><img src='/img/recipes/gallery/1.jpg' width='100' height="70" loading="lazy"  alt='robochef' /></a>
                  </div>
                  <div className='col-4'>
                    <a href='/img/recipes/gallery/2.jpg' data-group="1" className="galleryItem"><img src='/img/recipes/gallery/2.jpg' width='100' height="70" loading="lazy" alt=' robochef' /></a>
                  </div>
                  <div className='col-4'>
                    <a href='/img/recipes/gallery/3.jpg' data-group="1" className="galleryItem"><img  src='/img/recipes/gallery/3.jpg' width='100' height="70" loading="lazy" alt=' robochef' /></a>
                  </div>
                  <div className='col-4'>
                    <a href='/img/recipes/gallery/4.jpg' data-group="1" className="galleryItem"><img  src='/img/recipes/gallery/4.jpg' width='100' height="70" loading="lazy" alt=' RoboChef' /></a>
                  </div>
                  <div className='col-4'>
                    <a href='/img/recipes/gallery/5.jpg' data-group="1" className="galleryItem"><img  src='/img/recipes/gallery/5.jpg' width='100' height="70" loading="lazy" alt=' Robochef' /></a>
                  </div>
                  <div className='col-4'>
                    <a href='/img/recipes/gallery/6.jpg' data-group="1" className="galleryItem"><img src='/img/recipes/gallery/6.jpg' width='100' height="70" loading="lazy" alt=' Robochef' /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* <!-- Copywrite Area --> */}
    <div className='container'>
      <div className='copywrite-content'>
        <div className='row'>
          {/* <!-- Copywrite Text --> */}
          <div className='col-12 col-md-6'>
            <div className='copywrite-text'>
              <p>
                {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
                Copyright &copy;  2020<script>document.write(new Date().getFullYear());</script> 
                {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
              </p>
            </div>
          </div>
          {/* <!-- Footer Menu --> */}
          <div className='col-12 col-md-6'>
            <div className='footer-menu'>
              <ul className='nav'>
                {/* <li><a href='#'><i className='zmdi zmdi-circle' /> Terms of Service</a></li>
                <li><a href='#'><i className='zmdi zmdi-circle' /> Privacy Policy</a></li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
)
